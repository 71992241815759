import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Margin from "../components/margin"
import Leading from "../components/leading"
import SectionLine from "../components/section-line"

import { FaTwitter, FaTelegram, FaFacebook } from "react-icons/fa"


const Campaign1Page: React.FC = () => (
  <Layout>
    <Seo title="Campaign" />

    <div className="container">
      <Leading main="大口歓迎キャンペーン" sub=""/>
      <Margin height="2.0rem"/>
      <div className="campaign-title-bg">
        <strong className="campaign-title">
          <span style={{ fontSize: "120%" }}>今だけ運用者報酬から大還元！</span>
        </strong>
      </div>
      <Margin height="2.0rem"/>
      <p style={{ color: "navy", fontSize: "110%", fontWeight: "bold", textAlign: "center" }}>
        期間中に&nbsp;<span className="mono">1,000,000</span>&thinsp;<span className="mono">ADA</span>&nbsp;以上を委任すると<br />
        ブロック生成のあるエポック1回ごとに<br />
        <span className="mono">100</span>&thinsp;～&thinsp;<span className="mono">300</span>&thinsp;<span className="mono">ADA</span>&nbsp;をプレゼント！
      </p>
      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>キャンペーン報酬</strong>
        <div>
          参加者数によって変動いたします。<br />
          （最大3名、先着順）
        </div>
        <Margin height="1.0rem"/>
        <div style={{ background: 'ghostwhite', maxWidth: '300px', margin: 'auto', padding: "0.5rem", borderRadius: '10px' }}>
          <table style={{ textAlign: "left", maxWidth: "300px", margin: "auto" }}>
            <thead style={{ padding: "1rem", borderBottom: "1px solid slategray" }}>
              <tr>
                <td style={{ textAlign: "center"}}>参加者数</td>
                <td style={{ width: "2rem" }}></td>
                <td style={{ textAlign: "center"}}>報酬額</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>1 名</td>
                <td></td>
                <td className="mono" style={{ textAlign: "center" }}>300 ADA</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>2 名</td>
                <td></td>
                <td className="mono" style={{ textAlign: "center" }}>200 ADA</td>
              </tr>
               <tr>
                <td style={{ textAlign: "center" }}>3 名</td>
                <td></td>
                <td className="mono" style={{ textAlign: "center" }}>100 ADA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Margin height="1rem"/>

      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>キャンペーン期間</strong>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            エポック&thinsp;<span className="mono">335</span>&thinsp;～&thinsp;<span className="mono" style={{}}>344</span>
          </div>
        </div>
      </div>

      <Margin height="1rem" />

      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>参加方法</strong>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <div className="box" style={{ background: 'ghostwhite', padding: '0.5rem', maxWidth: '500px' }}>
              <div className="container-text">
                <span>&middot;キャンペーン内容と注意事項を確認</span>
                <br />
                <span>&middot;<span className="mono">1,000,000</span>&thinsp;<span className="mono">ADA</span>&nbsp;以上を&nbsp;<a href="https://adapools.org/pool/e8f538755c14d1e7f1274b46b5f292ba0c247149a8ba7b9eb410eb1a" target="_blank"><span className="mono">1STEP</span></a>&nbsp;へ委任開始</span>
                <br />
                <span>&middot;いずれかの方法で Nori までご連絡ください。</span>
                <br />
                <span>（委任日時と委任額の記載をお願い致します。）</span>
                <br />
                <div style={{ paddingLeft: '1rem' }}>
                  <Margin height="0.4rem" />
                  <a href={"https://twitter.com/nori_crypto"} target="_blank" style={{ display: "inline", color: 'dodgerblue' }}>
                    <span>Twitter DM</span>
                    &ensp;
                    <span>
                      <FaTwitter style={{display: "inline"}}/>@nori_crypto
                    </span>
                  </a>
                  <br />
                  <Margin height="0.4rem" />
                  <a href={"https://t.me/nori_crypto"} target="_blank" style={{ display: "inline", color: 'dodgerblue' }}>
                    <span>Telegram メッセージ</span>
                    &ensp;
                    <span>
                      <FaTelegram style={{display: "inline"}}/>nori_crypto
                    </span>
                  </a>
                  <br />
                  <Margin height="0.4rem" />
                  <a href={"https://www.facebook.com/nori.crypto"} target="_blank" style={{ display: "inline", color: 'dodgerblue' }}>
                    <span>Facebook メッセージ</span>
                    &ensp;
                    <span>
                      <FaFacebook style={{display: "inline"}}/>Noriaki
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Margin height="1rem" />

      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>注意事項</strong>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <div className="box" style={{ background: 'ghostwhite', padding: '0.5rem', maxWidth: '560px' }}>
              <div className="container-text">
                <small>&middot;参加は先着順となります（入れ替わりあり）。</small>
                <br />
                <small>&middot;透明性のため、報酬実績を公開する場合があります。</small>
                <br />
                <small>（受領者情報はCardanoのアドレスのみ公開し、その他の情報に関しては秘匿いたします。）</small>
                <br />
                <small>&middot;キャンペーン期間は延長する場合があります。</small>
                <br />
                <small>&middot;報酬の納税については、お住いの地域の税理士や税務署などへお問い合わせください。</small>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <Margin height="0.5rem"/>
    <SectionLine />

    <div className="container">
      <p>
        <Link to="/" style={{ display: "inline" }}>
          <strong>トップページ - Top Page</strong>
        </Link>
      </p>
    </div>

  </Layout>
)

export default Campaign1Page
